/** @jsx jsx */

import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { css, jsx } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../components/Seo'

import over from '../images/png/over.png'
import renderStructuredText from '../util/StructuredTextToHtml'
import { variables } from '../styles/global'

export const About = ({ data }: any) => {
  const aboutData = data.allDatoCmsAbout.edges[0].node
  return (
    <Layout>
      <SEO title="Info / Contact" />

      <div className="container">
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <div
            css={css`
              width: 45%;
              margin-top: 20px;
              margin-right: 40px;
              ${variables.mobile} {
                margin-right: 0;
                width: 100%;
              }
            `}
          >
            <GatsbyImage
              image={aboutData.afbeelding.gatsbyImageData}
              alt="foto van Marjan"
            />
            <div>
              <div
                css={css`
                  user-select: text;
                `}
                href="mailto:marjanderidder@live.be"
              >
                Mail: marjanderidder@live.be
              </div>
              <a
                css={css`
                  display: block;
                  text-decoration: underline;
                  margin-top: 5px;
                `}
                href="https://www.instagram.com/ik_maak_anders_iets/"
                target="_blank"
              >
                Instagram
              </a>
              <a
                css={css`
                  display: block;
                  text-decoration: underline;
                  margin-top: 5px;
                `}
                href="https://www.facebook.com/marjan.deridder"
                target="_blank"
              >
                Facebook
              </a>
            </div>
          </div>
          <div
            css={css`
              width: 45%;
              ${variables.mobile} {
                margin-right: 0;
                width: 100%;
                text-align: justify;
                margin-top: 50px;
              }
            `}
            dangerouslySetInnerHTML={{
              __html: renderStructuredText(aboutData.text.value),
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query aboutQuery {
    allDatoCmsAbout {
      edges {
        node {
          afbeelding {
            gatsbyImageData(imgixParams: { fit: "FULL_WIDTH" })
          }
          text {
            value
          }
          titel
        }
      }
    }
  }
`

export default About
